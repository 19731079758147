// src/nodes/lib/IframeComponent.js
import React, { useEffect, useState } from 'react';

const IframeComponent = ({ response }) => {
  const [iframeUrl, setIframeUrl] = useState('');

  useEffect(() => {
    if (response) {
      setIframeUrl(response);
    }
  }, [response]);

  return (
    <div className="master-node-iframe-container">
      <iframe
        src={iframeUrl}
        title="Iframe"
        className="master-node-iframe"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default IframeComponent;
