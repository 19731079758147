// src/nodes/lib/InputComponent.js
import React from 'react';

const InputComponent = ({ value, onChange, placeholder }) => (
  <input
    type="text"
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    className="master-node-input"
  />
);

export default InputComponent;
