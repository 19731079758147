import React from 'react';
import { Handle } from 'react-flow-renderer';
import './MasterNode.css';

// Import der ausgelagerten Komponenten
import InputComponent from './lib/InputComponent';
import NumberComponent from './lib/NumberComponent';
import DropdownComponent from './lib/DropdownComponent';
import KeyValueComponent from './lib/KeyValueComponent';
import TextareaComponent from './lib/TextareaComponent';
import CheckboxComponent from './lib/CheckboxComponent';
import SliderComponent from './lib/SliderComponent';
import FileUploadComponent from './lib/FileUploadComponent';
import IframeComponent from './lib/IframeComponent';
import LogComponent from './lib/LogComponent';
import { saveNodesToFirebase, saveToLocalStorage } from '../database';

// Hauptkomponente: MasterNode
const MasterNode = ({ id, data, edges, setEdges, inputData, isSelected, onClick, sessionId, nodes }) => {
  let nodeSetup = data.setup || {};

  // Hilfsfunktionen zum Umgang mit Verbindungen
  const removeEdgeFromHandle = (handleId) => {
    setEdges((eds) => {
      const updatedEdges = eds.filter((edge) => edge.sourceHandle !== handleId && edge.targetHandle !== handleId);
      
      // Speichere die aktualisierten Daten in Firebase und lokal
      saveNodesToFirebase(sessionId, nodes, updatedEdges, false);  // Daten in Firebase speichern
      saveToLocalStorage(nodes, updatedEdges);  // Daten lokal speichern

      return updatedEdges;  // Gib die aktualisierten Edges zurück
    });
  };

  // Funktionen zur Verwaltung von Key-Value-Paaren
  const handleKeyValueChange = (field, value, index, kvIndex) => {
    const newKeyValue = [...(data[nodeSetup.items[index].title.toLowerCase()] || [])];
    newKeyValue[kvIndex][field] = value;
    data.onChange({ target: { value: newKeyValue } }, nodeSetup.items[index].title.toLowerCase());
  };

  
  const addKeyValuePair = (index) => {
    const newKeyValue = [...(data[nodeSetup.items[index].title.toLowerCase()] || []), { key: '', value: '' }];
    data.onChange({ target: { value: newKeyValue } }, nodeSetup.items[index].title.toLowerCase());
  };

  const removeKeyValuePair = (index, kvIndex) => {
    const newKeyValue = data[nodeSetup.items[index].title.toLowerCase()].filter((_, i) => i !== kvIndex);
    data.onChange({ target: { value: newKeyValue } }, nodeSetup.items[index].title.toLowerCase());
  };

  // Funktion zur Verwaltung des Datei-Uploads
  const handleFileUpload = (file) => {
    data.onChange({ target: { value: file.name } }, 'file');
  };

  // Stildefinition für das Node-Element
  const nodeStyle = {
    width: nodeSetup.width || '350px',
    height: nodeSetup.height || 'auto',
    borderTop: `10px solid ${nodeSetup.topbarColor || '#2c3e50'}`
  };

  // Klick-Handler für das Node-Element
  const handleNodeClick = (event) => {
    if (
      event.target.tagName === 'INPUT' ||
      event.target.tagName === 'TEXTAREA' ||
      event.target.tagName === 'SELECT'  // SELECT hinzugefügt für Dropdowns
    ) {
      return;
    }
    onClick(event);
  };

  const handleNodeDrag = (event) => {
    console.log('Node is being dragged');
    alert('Node is being dragged');
    data.setIsDragging(true); // Setzt den isDragging State in der App-Komponente
  };

  const handleNodeDragStop = (event) => {
    console.log('Node drag stopped');
    data.setIsDragging(false); // Setzt den isDragging State in der App-Komponente zurück
  };

  // Rendering der MasterNode-Komponente
  return (
    <div
      className={`master-node ${isSelected ? 'selected' : ''}`}
      style={nodeStyle}
      onClick={handleNodeClick}
      draggable={false}  // Draggable deaktivieren
      onDragStart={handleNodeDrag}   // DragStart-Ereignis verhindern
      onDragEnd={handleNodeDragStop}
    >
      <div className="master-node-header">
        <span>{nodeSetup.title}</span>
      </div>

      {/* Verbindungen (Inputs und Outputs) */}
      <div className="master-node-connections">
        <div className="master-node-inputs">
          {nodeSetup.imports && nodeSetup.imports.map((input, index) => (
            <div key={index} className="master-node-connection">
              <Handle
                type="target"
                position="left"
                id={`${id}-${input.type.toLowerCase()}`}
                style={{ backgroundColor: input.color || 'white' }}
                onMouseDown={(event) => {
                  if (event.detail === 2) { // Überprüft, ob es ein Doppelklick ist
                    removeEdgeFromHandle(`${id}-${input.type.toLowerCase()}`);
                  }
                }}
              />
              <span>{input.title}</span>
            </div>
          ))}
        </div>
        <div className="master-node-outputs">
          {nodeSetup.exports && nodeSetup.exports.map((output, index) => (
            <div className="master-node-connection" key={index}>
              <span>{output.title || output}</span>
              <Handle
                type="source"
                position="right"
                id={`${id}-${output.type ? output.type.toLowerCase() : output.toLowerCase()}`}
                style={{ backgroundColor: output.color || 'white' }} // Farbe aus Setup-Datei entnommen
                onDoubleClick={() => removeEdgeFromHandle(`${id}-${output.type ? output.type.toLowerCase() : output.toLowerCase()}`)}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Node-Inhalt (dynamische Komponenten basierend auf nodeSetup.items) */}
      <div className="master-node-body">
        {nodeSetup.items.map((item, index) => (
          <div key={index} className="master-node-group">
            <span className="node-item-title">{item.title}</span>
            {item.type === 'string' && (
              <InputComponent
                id={index}
                value={data[item.title.toLowerCase()]}
                onChange={(e) => data.onChange(e, item.title.toLowerCase())}
                placeholder={item.placeholder}
              />
            )}
            {item.type === 'number' && (
              <NumberComponent
                id={index}
                value={data[item.title.toLowerCase()]}
                onChange={(e) => data.onChange(e, item.title.toLowerCase())}
              />
            )}
            {item.type === 'dropdown' && (
              <DropdownComponent
                id={index}
                value={data[item.title.toLowerCase()]}
                onChange={(e) => data.onChange(e, item.title.toLowerCase())}
                options={item.options}
              />
            )}
            {item.type === 'keyValue' && (
              <KeyValueComponent
                id={index}
                keyValuePairs={data[item.title.toLowerCase()] || []}
                onKeyChange={(e, kvIndex) => handleKeyValueChange('key', e.target.value, index, kvIndex)}
                onValueChange={(e, kvIndex) => handleKeyValueChange('value', e.target.value, index, kvIndex)}
                onAdd={() => addKeyValuePair(index)}
                onRemove={(kvIndex) => removeKeyValuePair(index, kvIndex)}
              />
            )}
            {item.type === 'textarea' && (
              <TextareaComponent
                id={index}
                value={data[item.title.toLowerCase()]}
                onChange={(e) => data.onChange(e, item.title.toLowerCase())}
                placeholder={`Enter ${item.title}`}
              />
            )}
            {item.type === 'checkbox' && (
              <CheckboxComponent
                id={index}
                options={item.options}
                values={data}
                onChange={(value) => data.onChange({ target: { value: !data[value] } }, value)}
              />
            )}
            {item.type === 'slider' && (
              <SliderComponent
                min={item.min}
                max={item.max}
                step={item.step}
                id={index}
                value={data[item.title.toLowerCase()] || item.min}
                onChange={(e) => data.onChange(e, item.title.toLowerCase())}
              />
            )}
            {item.type === 'file' && (
              <FileUploadComponent
                onUpload={handleFileUpload}
                fileName={data.file}
              />
            )}
            {item.type === 'iframe' && (
              <IframeComponent
                response={data.response}
              />
            )}
            {item.type === 'log' && (
              <LogComponent logData={data} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MasterNode;
