// src/nodes/lib/NumberComponent.js
import React from 'react';

const NumberComponent = ({ value, onChange }) => (
  <input
    type="number"
    value={value}
    onChange={onChange}
    className="master-node-input"
  />
);

export default NumberComponent;
