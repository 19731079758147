import { loadSessionId, saveNodesToFirebase, loadFromFirebase, checkSessionExists, saveNodePositionToFirebase, loadNeurolinks } from './database';
import React, { useCallback, useRef, useMemo, useEffect, useState } from 'react';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    // MiniMap,
    Background,
    useNodesState,
    useEdgesState,
} from 'react-flow-renderer';
import MasterNode from './nodes/MasterNode';
import ContextMenu from './ContextMenu';
import FloatingToolbar from './FloatingTopBar';
import './App.css';
// import { setNodeRunningStatus } from './nodes/nodeStatus';
// import initNodes from './initNodes.json';
import GroupNode from './nodes/GroupNode';
import SearchComponent from './SearchComponent';

const originalWarn = console.warn;
console.warn = function (message, ...args) {
    if (typeof message === 'string' && message.includes('It looks like you have created a new nodeTypes or edgeTypes object')) {
        return;
    }
    originalWarn.apply(console, [message, ...args]);
};


const loadNodeSetups = async () => {
    const context = require.context('./nodeSetups', true, /\.json$/);
    const setups = {};

    context.keys().forEach((key) => {
        const nodeType = key.replace('./', '').replace('.json', '');
        setups[nodeType] = context(key);
    });
    await loadNeurolinks();

    return setups;
};

// Local Storage Handlers
const saveToLocalStorage = (nodes, edges) => {
    localStorage.setItem('nodes', JSON.stringify(nodes));
    localStorage.setItem('edges', JSON.stringify(edges));
};

const clearLocalStorage = () => {
    localStorage.removeItem('nodes');
    localStorage.removeItem('edges');
};


// File Download/Upload Handlers
const downloadJSON = (data, filename) => {
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${filename}.lams`;
    link.click();
    URL.revokeObjectURL(url);
};

// Main App Component
const App = () => {
    const sessionId = loadSessionId();
    // State Management
    const [nodes, setNodes,] = useNodesState([]);
    const [edges, setEdges,] = useEdgesState([]);
    const [nodeSetups, setNodeSetups] = useState({});
    const [isDragging, setIsDragging] = useState(false);
    const [contextMenu, setContextMenu] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const reactFlowWrapper = useRef(null);
    const reactFlowInstance = useRef(null);
    const [copiedNode, setCopiedNode] = useState(null);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    // Initial Setup: Loading Nodes and Edges
    useEffect(() => {
        const fetchNodeSetups = async () => {
            const setups = await loadNodeSetups();

            // Neurolinks aus Firebase laden
            const neurolinks = await loadNeurolinks();

            // Neurolinks filtern
            const filteredNeurolinks = Object.keys(neurolinks).reduce((acc, key) => {
                const neurolinkData = neurolinks[key];


                // Prüfen, ob das Objekt ein 'neurolink' enthält
                if (neurolinkData && neurolinkData.neurolink) {
                    acc[neurolinkData.neurolink.title.split(' - ')[0]] = neurolinkData.neurolink;
                }
                return acc;
            }, {});


            const combinedSetups = { ...setups, ...filteredNeurolinks };

            setNodeSetups(combinedSetups);
            loadFromFirebase(sessionId, setNodes, setEdges, isDragging); // Lade Daten aus Firebase
        };

        fetchNodeSetups();
    }, [setNodes, setEdges, sessionId, isDragging]);



    // Node Event Handlers
    const handleNodeChange = useCallback((e, field, nodeId) => {
        setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId && node.data[field] !== e.target.value) {  // Zustand nur aktualisieren, wenn der Wert sich ändert
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            [field]: e.target.value,
                        },
                    };
                }
                return node;
            })
        );
    }, [setNodes]);


    const handleNodeClick = useCallback(
        (event, nodeId) => {
            setSelectedNodes((prevSelectedNodes) => {
                if (event.metaKey || event.shiftKey) {
                    return prevSelectedNodes.includes(nodeId)
                        ? prevSelectedNodes.filter((id) => id !== nodeId)
                        : [...prevSelectedNodes, nodeId];
                } else {
                    return [nodeId];
                }
            });
        },
        [setSelectedNodes]
    );

    const handleCanvasClick = useCallback(
        (event) => {
            closeContextMenu();

            if (
                reactFlowWrapper.current &&
                reactFlowWrapper.current.contains(event.target) &&
                !event.target.closest('.react-flow__node') &&
                !event.target.closest('.react-flow__edge')
            ) {
                setSelectedNodes([]); // Deselektiere alle Nodes
            }
        },
        [setSelectedNodes]
    );

    // Node Types Definition
    const nodeTypes = useMemo(() => ({
        masterNode: (props) => (
            <MasterNode
                {...props}
                edges={edges}
                setEdges={setEdges}
                setNodes={setNodes}
                sessionId={sessionId}
                nodes={nodes}
                data={{
                    ...props.data,
                    onChange: (e, field) => handleNodeChange(e, field, props.id),
                }}
                inputData={props.inputData}
                isSelected={selectedNodes.includes(props.id)}
                onClick={(event) => handleNodeClick(event, props.id)}
            />
        ),

        loopNode: (props) => (
            <MasterNode
                {...props}
                edges={edges}
                setEdges={setEdges}
                setNodes={setNodes}
                sessionId={sessionId}
                nodes={nodes}
                data={{
                    ...props.data,
                    onChange: (e, field) => handleNodeChange(e, field, props.id),
                }}
                inputData={props.inputData}
                isSelected={selectedNodes.includes(props.id)}
                onClick={(event) => handleNodeClick(event, props.id)}
            />
        ),

        groupNode: (props) => (
            <GroupNode
                {...props}
                isSelected={selectedNodes.includes(props.id)}
                onClick={(event) => handleNodeClick(event, props.id)}
            />
        ),
    }), [edges, setEdges, handleNodeChange, selectedNodes, handleNodeClick, nodes, sessionId, setNodes]);

    // Edge Connection Logic
    const onConnect = useCallback((params) => {
        const { sourceHandle, targetHandle } = params;

        const sourceType = sourceHandle.split('-')[1];
        const targetType = targetHandle.split('-')[1];

        const sourceNode = nodes.find(node => node.id === params.source);
        const targetNode = nodes.find(node => node.id === params.target);

        if (sourceNode && targetNode) {
            setEdges((eds) => {
                const updatedEdges = addEdge({ ...params, type: 'step', sourceHandle, targetHandle }, eds); // Füge die neue Edge hinzu
                saveNodesToFirebase(sessionId, nodes, updatedEdges, false); // Speichere die aktualisierten Nodes und Edges
                return updatedEdges; // Rückgabe der aktualisierten Edges
            });
        }

        if (!sourceNode || !targetNode) {
            return;
        }

        const sourcePort = sourceNode.data.setup.exports.find(port => port.type.toLowerCase() === sourceType.toLowerCase());
        const targetPort = targetNode.data.setup.imports.find(port => port.type.toLowerCase() === targetType.toLowerCase());


        targetNode.data[`${targetPort.type}_syn`] = sourceNode.id

        const isWhite = (color) => color === 'white' || color === '#ffffff';

        function updateNodeKey(nodes, sourceNode, targetPort) {

            setNodes(nodes);
        }

        updateNodeKey(nodes, sourceNode, targetPort)


        if (sourcePort?.color && targetPort?.color && (sourcePort.color === targetPort.color || isWhite(sourcePort.color) || isWhite(targetPort.color))) {
            setEdges((eds) => addEdge({ ...params, type: 'step' }, eds));
        } else {
        }
    }, [nodes, setEdges, sessionId, setNodes]);


    // Node Creation
    const handleSelectNode = useCallback((type) => {
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const position = reactFlowInstance.current.project({
            x: mousePosition.x - reactFlowBounds.left,
            y: mousePosition.y - reactFlowBounds.top,
        });

        const nodeSetup = nodeSetups[type];

        const newNode = {
            id: `${Date.now()}`,  // Eindeutige ID
            type: type === 'loopNode' ? 'loopNode' : 'masterNode',  // Prüfen, ob es ein LoopNode ist
            position,
            data: {
                setup: nodeSetup,
            },
        };

        setNodes((nds) => nds.concat(newNode));
        setContextMenu(null);
    }, [mousePosition.x, mousePosition.y, nodeSetups, setNodes]);


    // Download and Upload Handlers
    const handleDownload = () => {
        const state = { nodes, edges };
        downloadJSON(state, 'Neuroflow');
    };

    const handleUpload = (event) => {
        const file = event.target.files[0];

        // Überprüfe, ob die Datei die Endung .lams hat
        if (!file.name.endsWith('.lams')) {
            console.error('Invalid file format. Please upload a .lams file.');
            return;
        }

        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            try {
                const { nodes, edges } = JSON.parse(e.target.result);

                // Leere den State und lösche den Local Storage
                setNodes([]);
                setEdges([]);
                clearLocalStorage();

                setTimeout(() => {
                    // Setze die hochgeladenen Nodes und Edges
                    setNodes(nodes);
                    setEdges(edges);
                    saveToLocalStorage(nodes, edges);
                    saveNodesToFirebase(sessionId, nodes, edges, isDragging);
                }, 100);
            } catch (error) {
                console.error('Error parsing .lams file:', error);
            }
        };
        fileReader.readAsText(file);
    };

    const saveSessionName = (id) => {
        const sessionName = prompt('Welchen Namen soll das neue Projekt erhalten?');
        if (!sessionName) {
            alert('Bitte gib einen gültigen Namen ein.');
            return;
        }

        let sessionData = JSON.parse(localStorage.getItem('sessionData')) || {};
        sessionData[id] = sessionName;
        localStorage.setItem('sessionData', JSON.stringify(sessionData));
    };

    const handleClearWorkflow = () => {
        setNodes([]);
        setEdges([]);
        clearLocalStorage();
        let newId = Date.now().toString();
        saveSessionName(newId);
        localStorage.setItem('sessionId', newId);
    };

    // Context Menu Handlers
    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu({
            mouseX: event.clientX,
            mouseY: event.clientY,
        });
    };

    const closeContextMenu = () => {
        setContextMenu(null);
    };













    const handlePlay = async () => {
        console.log("Start of handlePlay");
        setIsPlaying(true);
        const dependencyMap = buildDependencyMap();
        const executionWorkflow = determineExecutionWorkflow(dependencyMap);

        await executeWorkflow(executionWorkflow, dependencyMap);
        setIsPlaying(false);
    };

    // Funktion zum Erstellen der Abhängigkeitskarte
    const buildDependencyMap = () => {
        const dependencyMap = new Map();

        // Durchlaufe alle Nodes
        nodes.forEach(node => {
            const incomingEdges = edges.filter(edge => edge.target === node.id);
            const dependencies = incomingEdges.map(edge => edge.source);
            dependencyMap.set(node.id, dependencies);
        });


        return dependencyMap;
    };

    const applyLoopLogic = (executionWorkflow) => {
        let finalWorkflow = [...executionWorkflow];  // Kopiere den ursprünglichen Workflow

        // Hier speichern wir alle Loop-Indizes in einem Stack, um sie korrekt zu verwalten
        let loopContextStack = [];

        // Von hinten nach vorne durch die Workflow-Liste gehen
        for (let i = finalWorkflow.length - 1; i >= 0; i--) {
            const currentStep = finalWorkflow[i];
            const { nodeId } = currentStep;

            // Prüfe, ob der aktuelle Node ein Loop-Node ist
            const node = nodes.find(n => n.id === nodeId);
            if (node.data.setup.function === 'loop.js') {
                let loopCount = parseInt(node.data['loop count']) || 1; // Hole die Anzahl der Wiederholungen
                loopContextStack.push({ nodeId, loopCount });


                // Kopiere den Abschnitt, der nach dem Loop-Node liegt
                const subWorkflow = finalWorkflow.slice(i + 1); // Alles nach diesem Node
                const loopedSubWorkflow = [];

                // Füge die Kopien entsprechend der Anzahl der Loops hinzu
                for (let loopIndex = 0; loopIndex < loopCount; loopIndex++) {
                    let index = loopIndex

                    if (node.data.index_1) {
                        index++
                    }

                    subWorkflow.forEach(step => {
                        const updatedLoopContext = {
                            ...step.loopContext,
                            [nodeId]: index,  // Speichere den loopIndex basierend auf der Node-ID
                        };

                        // Klone die Schritte und füge den aktuellen Loop-Index hinzu
                        loopedSubWorkflow.push({
                            ...step,
                            loopContext: updatedLoopContext,
                        });
                    });
                }

                // Entferne den ursprünglichen Teil des Workflows, der zu oft kopiert wurde
                finalWorkflow = [
                    ...finalWorkflow.slice(0, i + 1), // Alles bis einschließlich des aktuellen Loop-Nodes
                    ...loopedSubWorkflow // Die neuen, wiederholten Schritte
                ];
            }
        }

        return finalWorkflow;
    };



    // Funktion zur Ausführung des Workflows
    const determineExecutionWorkflow = (dependencyMap) => {
        const executionWorkflow = [];  // Endgültiger Workflow
        const executedNodes = new Set();  // Nodes, die schon ausgeführt wurden

        // Funktion zur Ausführung von Nodes, inklusive Abhängigkeiten
        const executeNode = (nodeId, loopContext = {}) => {
            const node = nodes.find(n => n.id === nodeId);
            const nodeTitle = node.data.setup.title;
            const dependencies = dependencyMap.get(nodeId) || [];


            // Behandle alle Abhängigkeiten zuerst
            dependencies.forEach(depNodeId => {
                if (!executedNodes.has(depNodeId)) {
                    executeNode(depNodeId, loopContext);  // Abhängigkeit ausführen
                }
            });

            // Jetzt führe den aktuellen Node aus
            if (!executedNodes.has(nodeId)) {
                executionWorkflow.push({
                    nodeId,
                    nodeTitle,
                    data: node.data
                });
                executedNodes.add(nodeId);  // Markiere den Node als ausgeführt
            }
        };

        // Starte die Ausführung mit den Nodes, die von anderen Nodes verwendet werden
        nodes.forEach(node => {
            const dependencies = dependencyMap.get(node.id) || [];
            if (dependencies.length > 0 && !executedNodes.has(node.id)) {
                executeNode(node.id);
            }
        });

        // Danach füge Nodes hinzu, die keine Abhängigkeiten haben, aber Teil der Datenlieferung sind
        nodes.forEach(node => {
            if (!executedNodes.has(node.id)) {
                executeNode(node.id);
            }
        });

        // Wende die Schleifenlogik auf den Workflow an
        const finalExecutionWorkflow = applyLoopLogic(executionWorkflow);
        return finalExecutionWorkflow;
    };


    // Funktion zur Ausführung des finalen Workflows
    const executeWorkflow = async (workflow, dependencyMap) => {
        const nodeResults = {}; // Hier speichern wir die Ergebnisse der Nodes
        const executedLoops = {}; // Speichert den Loop-Status für jeden Knoten
        let lastLoopId;
        // Helper function to load and execute each node's function
        const executeNodeFunction = async (nodeId, nodeSetup, inputData, step, loopIndex) => {
            Object.keys(inputData).forEach(key => {
                Object.keys(step.data).forEach(key2 => {
                    if (step.data[key2] === key) {
                        inputData[key2.replace('_syn', '')] = inputData[key];
                        delete inputData[key];
                    }
                });
            });

            try {
                // Dynamically import the function for the current node
                const nodeFunction = await import(`./nodeFunctions/${nodeSetup.function}`);
                const result = await nodeFunction.default({ node: step, nodeId, loopIndex: loopIndex, synaps: inputData });
                return result;
            } catch (error) {
                console.error(`Error executing node ${nodeId} - ${nodeSetup.title}:`, error);
                throw error;
            }
        };


        // Loop through each node in the workflow
        for (const step of workflow) {
            const { nodeId, loopContext } = step; // Hole den Loop-Kontext hier

            // Verwende den Loop-Kontext, um den Index zu setzen oder zurückzusetzen
            if (!executedLoops[nodeId]) {
                executedLoops[nodeId] = 0;
            } else {
                executedLoops[nodeId] = (executedLoops[nodeId] + 1) % step.loopCount;
            }

            const node = nodes.find(n => n.id === nodeId);
            const nodeSetup = node.data.setup;

            // Get input data from dependencies (previously executed nodes)
            const inputData = {};
            const dependencies = dependencyMap.get(nodeId) || [];
            dependencies.forEach(depNodeId => {
                inputData[depNodeId] = nodeResults[depNodeId]; // Collect data from dependencies
            });

            if (step.nodeTitle.includes('Loop')) {
                lastLoopId = step.nodeId;
            }

            let loopIndex = null
            if (lastLoopId && loopContext && loopContext[lastLoopId] !== undefined) {
                loopIndex = loopContext[lastLoopId]
            }

            let loopContextKeys = Object.keys(loopContext || {})
            if (loopContext && loopContextKeys.length === 1) {
                loopIndex = loopContext[loopContextKeys[0]]
            }


            // Execute the node's function

            const outputData = await executeNodeFunction(nodeId, nodeSetup, inputData, step, loopIndex);

            // Store the result for future nodes
            nodeResults[nodeId] = outputData;


            if (outputData.error) {
                console.log(outputData);
                return;
            }

            // Log the result
        }

        return nodeResults;
    };































    const handleStop = () => {
        setIsPlaying(false);
    };

    const handleLogout = () => {
    };


    useEffect(() => {

        document.addEventListener('contextmenu', handleContextMenu);
        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    useEffect(() => {

        const handleMouseMove = (event) => {
            setMousePosition({
                x: event.clientX,
                y: event.clientY,
            });
        };

        const handleKeyDown = (event) => {
            // Überprüfe, ob der Fokus auf einem Eingabefeld oder einem anderen fokussierbaren Element liegt
            const activeElement = document.activeElement;
            const isInputFocused =
                activeElement.tagName === 'INPUT' ||
                activeElement.tagName === 'TEXTAREA' ||
                activeElement.isContentEditable;

            // Wenn ein Eingabefeld fokussiert ist, blockiere die Shortcuts
            if (isInputFocused) {
                return;
            }

            // Deine Shortcut-Logik, wenn kein Eingabefeld fokussiert ist
            if (event.key === ' ') {
                setIsSearchOpen((prev) => !prev);
            }

            if ((event.metaKey || event.ctrlKey) && event.key === 'c') {
                const selectedNode = nodes.find(node => node.selected);
                if (selectedNode) {
                    setCopiedNode({ ...selectedNode, id: `${Date.now()}` });
                }
            }

            if ((event.metaKey || event.ctrlKey) && event.key === 'v' && copiedNode) {
                const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
                const position = reactFlowInstance.current.project({
                    x: reactFlowBounds.width / 2,
                    y: reactFlowBounds.height / 2,
                });

                const newNode = {
                    ...copiedNode,
                    position,
                    id: `${Date.now()}`,
                };
                setNodes((nds) => nds.concat(newNode));
            }
        };


        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [nodes, copiedNode, setNodes, selectedNodes, isSearchOpen]);

    // const handleNodeDragStart = (event) => {
    //     setIsDragging(true); // Setzt den State auf "dragging"
    // };

    const handleNodeDragStop = (event, node) => {
        setTimeout(() => {
            setIsDragging(false); // Beendet den Dragging-Zustand
        }, 2000);
        saveNodesToFirebase(sessionId, nodes, edges); // Letzte Position speichern
    };

    const handleNodesDelete = useCallback(
        () => {
            if (selectedNodes.length === 0) {
                console.error("Keine Nodes ausgewählt zum Löschen");
                return;
            }

            // Lösche nur die ausgewählten Nodes
            const remainingNodes = nodes.filter((node) =>
                !selectedNodes.includes(node.id)
            );

            // Lösche die Edges, die mit den ausgewählten Nodes verbunden sind
            const remainingEdges = edges.filter((edge) =>
                !selectedNodes.includes(edge.source) && !selectedNodes.includes(edge.target)
            );

            // Aktualisiere den State
            setNodes(remainingNodes);
            setEdges(remainingEdges);

            // Aktualisiere die Daten in Firebase und im Local Storage
            saveNodesToFirebase(sessionId, remainingNodes, remainingEdges, isDragging);
            setSelectedNodes([]);  // Setze selectedNodes zurück, nachdem sie gelöscht wurden
        },
        [selectedNodes, nodes, edges, setNodes, setEdges, sessionId, isDragging]
    );



    const handleNodeDrags = (event, node) => {
        // Position des Nodes aktualisieren
        saveNodePositionToFirebase(sessionId, node.id, node);
    };


    const mouseLeave = (event, node) => {
        saveNodesToFirebase(sessionId, nodes, edges, false);  // Speichere Nodes und Edges in Firebase
        saveToLocalStorage(nodes, edges);
    };

    return (
        <div onClick={handleCanvasClick} className="app-container" onContextMenu={handleContextMenu}>
            <div className="main-content" ref={reactFlowWrapper}>
                <ReactFlowProvider>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodeMouseLeave={mouseLeave}
                        onConnect={onConnect}
                        fitView
                        nodeTypes={nodeTypes}
                        onInit={(rfi) => (reactFlowInstance.current = rfi)}
                        defaultZoom={0.1}
                        minZoom={0.2}
                        maxZoom={1.5}
                        onNodeDrag={handleNodeDrags}
                        onNodeDragStop={handleNodeDragStop}
                        onNodesDelete={handleNodesDelete}
                    >
                        <Background />
                    </ReactFlow>
                    {isSearchOpen && (
                        <SearchComponent
                            nodeSetups={nodeSetups}
                            onAddNode={handleSelectNode}
                            onClose={() => setIsSearchOpen(!isSearchOpen)}
                        />
                    )}
                </ReactFlowProvider>
                <ContextMenu
                    contextMenu={contextMenu}
                    onClose={closeContextMenu}
                    onDownload={handleDownload}
                    onUpload={handleUpload}
                    onClear={handleClearWorkflow}
                    nodeSetups={nodeSetups}
                    onAddNode={handleSelectNode}
                />
                <FloatingToolbar
                    nodeCount={nodes.length}
                    onPlay={handlePlay}
                    onStop={handleStop}
                    onLogout={handleLogout}
                    onClearNodes={handleClearWorkflow}
                    onDownload={handleDownload}
                    onUpload={handleUpload}
                    isPlaying={isPlaying}
                    loadFromFirebase={loadFromFirebase}
                    setNodes={setNodes}
                    setEdges={setEdges}
                    checkSessionExists={checkSessionExists}
                    nodes={nodes}
                    edges={edges}
                />
            </div>
        </div>
    );
};

export default App;
