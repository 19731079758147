// src/nodes/lib/KeyValueComponent.js
import React from 'react';

const KeyValueComponent = ({ keyValuePairs, onKeyChange, onValueChange, onAdd, onRemove }) => (
  <div>
    {keyValuePairs.map((kv, kvIndex) => (
      <div key={kvIndex} className="master-node-key-value">
        <input
          type="text"
          placeholder="Enter Key"
          value={kv.key || ''}
          onChange={(e) => onKeyChange(e, kvIndex)}
          className="master-node-input"
        />
        :
        <input
          type="text"
          placeholder="Enter Value"
          value={kv.value || ''}
          onChange={(e) => onValueChange(e, kvIndex)}
          className="master-node-input"
        />
        <button className="master-node-remove-option" onClick={() => onRemove(kvIndex)}>
          &times;
        </button>
      </div>
    ))}
    <button className="master-node-add-option" onClick={onAdd}>
      Add Option
    </button>
  </div>
);

export default KeyValueComponent;
