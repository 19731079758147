// src/nodes/lib/CheckboxComponent.js
import React from 'react';

const CheckboxComponent = ({ options, values, onChange }) => (
  <div className="master-node-checkbox-container">
    {options.map((option, i) => (
      <div key={i} className="master-node-checkbox-group">
        <span>{option.label}</span>
        <div
          className={`master-node-checkbox-button ${values[option.value] ? 'true' : 'false'}`}
          onClick={() => onChange(option.value)}
        >
          {values[option.value] ? 'true' : 'false'}
        </div>
      </div>
    ))}
  </div>
);

export default CheckboxComponent;
