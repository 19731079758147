import { loadSessionId, saveNodesToFirebase, loadFromFirebase, checkSessionExists, saveNodePositionToFirebase } from './database';
import React, { useCallback, useRef, useMemo, useEffect, useState } from 'react';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    // MiniMap,
    Background,
    useNodesState,
    useEdgesState,
} from 'react-flow-renderer';
import MasterNode from './nodes/MasterNode';
import ContextMenu from './ContextMenu';
import FloatingToolbar from './FloatingTopBar';
import './App.css';
import { setNodeRunningStatus } from './nodes/nodeStatus';
// import initNodes from './initNodes.json';
import GroupNode from './nodes/GroupNode';
import SearchComponent from './SearchComponent';
import NodeManager from './NodeManager';
// import { applyEdgeChanges } from 'react-flow-renderer';


const originalWarn = console.warn;
console.warn = function (message, ...args) {
    if (typeof message === 'string' && message.includes('It looks like you have created a new nodeTypes or edgeTypes object')) {
        return;
    }
    originalWarn.apply(console, [message, ...args]);
};

// Utility Functions
const loadNodeFunction = async (functionName) => {
    console.log('Run Neuro:', functionName);
    return import(`./nodeFunctions/${functionName}`);
};

const loadNodeSetups = async () => {
    const context = require.context('./nodeSetups', true, /\.json$/);
    const setups = {};

    context.keys().forEach((key) => {
        const nodeType = key.replace('./', '').replace('.json', '');
        setups[nodeType] = context(key);
    });

    return setups;
};

// Local Storage Handlers
const saveToLocalStorage = (nodes, edges) => {
    localStorage.setItem('nodes', JSON.stringify(nodes));
    localStorage.setItem('edges', JSON.stringify(edges));
};

const clearLocalStorage = () => {
    localStorage.removeItem('nodes');
    localStorage.removeItem('edges');
};


// File Download/Upload Handlers
const downloadJSON = (data, filename) => {
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
};

// Main App Component
const App = () => {
    const sessionId = loadSessionId();
    // State Management
    const [nodes, setNodes,] = useNodesState([]);
    const [edges, setEdges,] = useEdgesState([]);
    const [nodeSetups, setNodeSetups] = useState({});
    const [isDragging, setIsDragging] = useState(false);
    const [contextMenu, setContextMenu] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const reactFlowWrapper = useRef(null);
    const reactFlowInstance = useRef(null);
    const [copiedNode, setCopiedNode] = useState(null);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    // Initial Setup: Loading Nodes and Edges
    useEffect(() => {
        const fetchNodeSetups = async () => {
            const setups = await loadNodeSetups();
            setNodeSetups(setups);
            loadFromFirebase(sessionId, setNodes, setEdges, isDragging); // Lade Daten aus Firebase
        };

        fetchNodeSetups();
    }, [setNodes, setEdges, sessionId, isDragging]);

    useEffect(() => {

        if (nodes.length > 0 || edges.length > 0) {
            // saveNodesToFirebase(sessionId, nodes, edges, isDragging); // Speichere Daten in Firebase
        }
    }, [nodes, edges, sessionId, isDragging]);

    // Node Event Handlers
    const handleNodeChange = useCallback((e, field, nodeId) => {
        setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        [field]: e.target.value,
                    };
                }
                return node;
            })
        );
    }, [setNodes]);

    const handleNodeClick = useCallback(
        (event, nodeId) => {
            setSelectedNodes((prevSelectedNodes) => {
                if (event.metaKey || event.shiftKey) {
                    return prevSelectedNodes.includes(nodeId)
                        ? prevSelectedNodes.filter((id) => id !== nodeId)
                        : [...prevSelectedNodes, nodeId];
                } else {
                    return [nodeId];
                }
            });
        },
        [setSelectedNodes]
    );

    const handleCanvasClick = useCallback(
        (event) => {
            closeContextMenu();

            if (
                reactFlowWrapper.current &&
                reactFlowWrapper.current.contains(event.target) &&
                !event.target.closest('.react-flow__node') &&
                !event.target.closest('.react-flow__edge')
            ) {
                setSelectedNodes([]); // Deselektiere alle Nodes
            }
        },
        [setSelectedNodes]
    );

    // Node Types Definition
    const nodeTypes = useMemo(() => ({
        masterNode: (props) => (
            <MasterNode
                {...props}
                edges={edges}
                setEdges={setEdges}
                setNodes={setNodes}
                sessionId={sessionId}
                nodes={nodes}
                data={{
                    ...props.data,
                    onChange: (e, field) => handleNodeChange(e, field, props.id),
                }}
                inputData={props.inputData}
                isSelected={selectedNodes.includes(props.id)}
                onClick={(event) => handleNodeClick(event, props.id)}
            />
        ),
        groupNode: (props) => (
            <GroupNode
                {...props}
                isSelected={selectedNodes.includes(props.id)}
                onClick={(event) => handleNodeClick(event, props.id)}
            />
        ),
    }), [edges, setEdges, handleNodeChange, selectedNodes, handleNodeClick, nodes, sessionId, setNodes]);


    // Edge Connection Logic
    const onConnect = useCallback((params) => {
        console.log('Connect');
    
        const { sourceHandle, targetHandle } = params;
    
        // Fehlerprüfung: Überprüfe, ob die Handles vorhanden sind
        if (!sourceHandle || !targetHandle) {
            console.log("SourceHandle oder TargetHandle fehlt.");
            return;
        }
    
        const sourceType = sourceHandle.split('-')[1];
        const targetType = targetHandle.split('-')[1];
    
        const sourceNode = nodes.find(node => node.id === params.source);
        const targetNode = nodes.find(node => node.id === params.target);
    
        // Überprüfe, ob sowohl die Source- als auch die Target-Nodes existieren
        if (!sourceNode || !targetNode) {
            console.log("Source or Target Node not found.");
            return;
        }
    
        // Zugriff auf die Exporte und Importe aus dem Setup des Nodes
        const sourcePort = sourceNode.data?.setup?.exports?.find(port => port.type.toLowerCase() === sourceType.toLowerCase());
        const targetPort = targetNode.data?.setup?.imports?.find(port => port.type.toLowerCase() === targetType.toLowerCase());
    
        // Überprüfe, ob die Ports gefunden wurden
        if (!sourcePort || !targetPort) {
            console.log("Ports für die Verbindung wurden nicht gefunden.");
            console.log("SourcePort:", sourcePort);
            console.log("TargetPort:", targetPort);
            return;
        }
    
        const isWhite = (color) => color === 'white' || color === '#ffffff';
    
        // Verbindung nur herstellen, wenn die Farben übereinstimmen oder eine der Farben weiß ist
        if (sourcePort?.color && targetPort?.color && (sourcePort.color === targetPort.color || isWhite(sourcePort.color) || isWhite(targetPort.color))) {
            setEdges((eds) => {
                const newEdge = { ...params, type: 'step' };
                const updatedEdges = addEdge(newEdge, eds); // Füge die neue Edge hinzu
                saveNodesToFirebase(sessionId, newEdge.id, nodes, updatedEdges, false);  // Speichere nur die neue Edge
                return updatedEdges;
            });
    
            // Aktualisiere den 'exportTo'-Wert des Source-Nodes
            function updateNodeKey(nodes, sourceNode, targetPort) {
                for (let i = 0; i < nodes.length; i++) {
                    if (nodes[i].id === sourceNode.id) {
                        nodes[i].data["exportTo"] = targetPort.type;
                        break;
                    }
                }
                setNodes(nodes);
            }
    
            updateNodeKey(nodes, sourceNode, targetPort);
        } else {
            console.log("Verbindung nicht erlaubt: Farben stimmen nicht überein.");
        }
    }, [nodes, setEdges, sessionId, setNodes]);
    
    





    // Node Creation
    const handleSelectNode = useCallback((type) => {
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const position = reactFlowInstance.current.project({
            x: mousePosition.x - reactFlowBounds.left,
            y: mousePosition.y - reactFlowBounds.top,
        });

        const nodeSetup = nodeSetups[type];

        const newNode = {
            id: `${Date.now()}`,  // Eindeutige ID
            type: 'masterNode',
            position,
            data: {
                setup: nodeSetup,
                // onChange: () => { },
            },
        };

        setNodes((nds) => nds.concat(newNode));
        setContextMenu(null);
    }, [mousePosition.x, mousePosition.y, nodeSetups, setNodes]);

    // Download and Upload Handlers
    const handleDownload = () => {
        const state = { nodes, edges };
        downloadJSON(state, 'LAMS-Workflow');
    };

    const handleUpload = (event) => {
        const file = event.target.files[0];

        if (file.name.endsWith('.lams')) {
            console.log('Upload event triggered for .lams file');

            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                try {
                    const { nodes, edges } = JSON.parse(e.target.result);

                    setNodes([]);
                    setEdges([]);
                    clearLocalStorage();

                    setTimeout(() => {
                        setNodes(nodes);
                        setEdges(edges);
                        saveToLocalStorage(nodes, edges);
                        saveNodesToFirebase(sessionId, nodes, edges, isDragging);
                    }, 100);
                } catch (error) {
                    console.error('Error parsing .lams file:', error);
                }
            };
            fileReader.readAsText(file);
        } else {
            console.error('Invalid file format. Please upload a .lams file.');
        }
    };

    const handleClearWorkflow = () => {
        setNodes([]);
        setEdges([]);
        clearLocalStorage();
        localStorage.setItem('sessionId', Date.now().toString());
    };

    // Context Menu Handlers
    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu({
            mouseX: event.clientX,
            mouseY: event.clientY,
        });
    };

    const closeContextMenu = () => {
        setContextMenu(null);
    };

    // Node Execution Logic
    // App.js

    const handlePlay = async () => {
        setIsPlaying(true);

        const findNextNodes = (currentNodeId) => {
            return edges
                .filter(edge => edge.source === currentNodeId)
                .map(edge => nodes.find(node => node.id === edge.target));
        };

        const calculateExpectedInputs = (nodeId) => {
            return edges.filter(edge => edge.target === nodeId).length;
        };

        const executeNode = async (node, inputData = {}) => {

            const nodeId = node.id;
            const { setup } = node.data;

            // Registriere die Node im NodeManager und lege die erwartete Anzahl an Eingaben fest
            const expectedInputs = calculateExpectedInputs(nodeId);
            NodeManager.registerNode(nodeId, expectedInputs);

            // Füge die empfangenen Daten zur Node hinzu
            NodeManager.addNodeData(nodeId, inputData);

            if (NodeManager.isNodeReady(nodeId)) {
                let outputData = {};
                if (setup.function) {
                    try {
                        setNodeRunningStatus(nodeId, true);
                        const nodeFunction = await loadNodeFunction(setup.function);

                        const functionInput = { ...node.data, ...NodeManager.getNodeData(nodeId) };

                        outputData = await nodeFunction.default(functionInput);

                        setNodes((nds) =>
                            nds.map((n) => {
                                if (n.id === nodeId) {
                                    return {
                                        ...n,
                                        data: {
                                            ...n.data,
                                            inputData: outputData,
                                        },
                                    };
                                }
                                return n;
                            })
                        );

                    } catch (error) {
                        console.error(`Error executing function ${setup.function}:`, error);
                    }
                } else {
                    console.warn(`Node ${nodeId} does not have a function defined.`);
                }

                const nextNodes = findNextNodes(nodeId);
                setNodeRunningStatus(nodeId, false);

                await Promise.all(nextNodes.map(nextNode => executeNode(nextNode, outputData)));

                // Lösche die Node-Daten nach der Ausführung
                NodeManager.clearNode(nodeId);
            } else {
                console.log(`Node ${nodeId} wartet auf weitere Daten...`);
            }
        };

        const resetNodes = () => {
            setNodes((nds) =>
                nds.map((node) => {
                    const cleanedNode = { ...node };
                    delete cleanedNode.data.inputData;
                    return cleanedNode;
                })
            );
        };

        const startNodes = nodes.filter(node =>
            !edges.some(edge => edge.target === node.id)
        );

        if (startNodes.length > 0) {
            await Promise.all(startNodes.map(startNode => executeNode(startNode)));
        } else {
            console.error('No start node found in the chain.');
        }

        setIsPlaying(false);
        resetNodes();
    };


    const handleStop = () => {
        setIsPlaying(false);
    };

    const handleLogout = () => {
        console.log('Logout triggered');
    };

    useEffect(() => {

        const fetchNodeSetups = async () => {
            const setups = await loadNodeSetups();
            setNodeSetups(setups);

            const sessionId = loadSessionId();

            // Lade Nodes und Edges aus Firebase
            loadFromFirebase(sessionId, setNodes, setEdges, isDragging)
        };
        fetchNodeSetups();
    }, [setNodes, setEdges, isDragging]);


    useEffect(() => {

        document.addEventListener('contextmenu', handleContextMenu);
        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    useEffect(() => {

        const handleMouseMove = (event) => {
            setMousePosition({
                x: event.clientX,
                y: event.clientY,
            });
        };

      
        const handleKeyDown = (event) => {
            if (event.key === ' ') {
                console.log("Leertaste gedrückt");
                setIsSearchOpen((prev) => !prev);
            }

            if ((event.metaKey || event.ctrlKey) && event.key === 'c') {
                const selectedNode = nodes.find(node => node.selected);
                if (selectedNode) {
                    setCopiedNode({ ...selectedNode, id: `${Date.now()}` });
                }
            }

            if ((event.metaKey || event.ctrlKey) && event.key === 'v' && copiedNode) {
                const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
                const position = reactFlowInstance.current.project({
                    x: reactFlowBounds.width / 2,
                    y: reactFlowBounds.height / 2,
                });

                const newNode = {
                    ...copiedNode,
                    position,
                    id: `${Date.now()}`,
                };
                setNodes((nds) => nds.concat(newNode));
            }

        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [nodes, copiedNode, setNodes, selectedNodes, isSearchOpen]);

    // const handleNodeDragStart = (event) => {
    //     setIsDragging(true); // Setzt den State auf "dragging"
    // };

    const handleNodeDragStop = (event, node) => {
        setTimeout(() => {
            setIsDragging(false); // Beendet den Dragging-Zustand
        }, 2000);
        saveNodesToFirebase(sessionId, node.id, nodes, edges, false); // Letzte Position speichern
    };

    const handleNodesDelete = useCallback(
        (deletedNodes) => {
            const remainingNodes = nodes.filter((node) => !deletedNodes.some((deletedNode) => deletedNode.id === node.id));
            const remainingEdges = edges.filter((edge) => !deletedNodes.some((deletedNode) => edge.source === deletedNode.id || edge.target === deletedNode.id));

            setNodes(remainingNodes);
            setEdges(remainingEdges);

            deletedNodes.forEach((deletedNode) => {
                saveNodesToFirebase(sessionId, deletedNode.id, remainingNodes, remainingEdges, false);  // Lösche den spezifischen Node
            });

            remainingEdges.forEach((remainingEdge) => {
                saveNodesToFirebase(sessionId, remainingEdge.id, remainingNodes, remainingEdges, false);  // Lösche die spezifische Edge
            });
        },
        [nodes, edges, setNodes, setEdges, sessionId]
    );

    const handleNodeDrags = (event, node) => {
        saveNodePositionToFirebase(sessionId, node.id, node);  // Position des Nodes aktualisieren
    };



    const mouseLeave = (event, node) => {
        saveNodesToFirebase(sessionId, node.id, nodes, edges, false);  // Speichere nur den spezifischen Node
        saveToLocalStorage(nodes, edges);  // Speichere die Nodes und Edges auch lokal
    };

    return (
        <div onClick={handleCanvasClick} className="app-container" onContextMenu={handleContextMenu}>
            <div className="main-content" ref={reactFlowWrapper}>
                <ReactFlowProvider>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodeMouseLeave={mouseLeave}
                        onConnect={onConnect}
                        fitView
                        nodeTypes={nodeTypes}
                        onInit={(rfi) => (reactFlowInstance.current = rfi)}
                        defaultZoom={0.1}
                        minZoom={0.2}
                        maxZoom={1.5}
                        onNodeDrag={handleNodeDrags}
                        onNodeDragStop={handleNodeDragStop}
                        onNodesDelete={handleNodesDelete}
                    >
                        <Background />
                    </ReactFlow>
                    {isSearchOpen && (
                        <SearchComponent
                            nodeSetups={nodeSetups}
                            onAddNode={handleSelectNode}
                            onClose={() => setIsSearchOpen(!isSearchOpen)}
                        />
                    )}
                </ReactFlowProvider>
                <ContextMenu
                    contextMenu={contextMenu}
                    onClose={closeContextMenu}
                    onDownload={handleDownload}
                    onUpload={handleUpload}
                    onClear={handleClearWorkflow}
                    nodeSetups={nodeSetups}
                    onAddNode={handleSelectNode}
                />
                <FloatingToolbar
                    nodeCount={nodes.length}
                    onPlay={handlePlay}
                    onStop={handleStop}
                    onLogout={handleLogout}
                    onClearNodes={handleClearWorkflow}
                    onDownload={handleDownload}
                    onUpload={handleUpload}
                    isPlaying={isPlaying}
                    loadFromFirebase={loadFromFirebase}
                    setNodes={setNodes}
                    setEdges={setEdges}
                    checkSessionExists={checkSessionExists}
                />
            </div>
        </div>
    );
};

export default App;
