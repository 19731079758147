import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, get, onValue } from 'firebase/database';

// Deine Firebase-Konfiguration
const firebaseConfig = {
    apiKey: "AIzaSyBRdMB_ruJk8WjuPPRqVOt-_kPmB5yC_1k",
    authDomain: "lams-40a90.firebaseapp.com",
    databaseURL: "https://lams-40a90-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "lams-40a90",
    storageBucket: "lams-40a90.appspot.com",
    messagingSenderId: "906643705894",
    appId: "1:906643705894:web:1e6fb889cbd91e3a94d6e7"
};

// Firebase-App initialisieren
const app = initializeApp(firebaseConfig);

// Datenbank aus der App exportieren
const database = getDatabase(app);

// let lastUploadTime = 0;
let lastDownloadTime = 0;
const delay = 2000; // 2 Sekunden
let isFirstLoad = true;

export const loadSessionId = () => {
    let sessionId = localStorage.getItem('sessionId');
    
    if (!sessionId) {
        sessionId = Date.now().toString(); // Neue Session-ID generieren
        localStorage.setItem('sessionId', sessionId);
    }

    // Lade die Session-IDs Liste aus dem Local Storage
    let sessionIds = JSON.parse(localStorage.getItem('sessionIds')) || [];
    
    // Überprüfe, ob die aktuelle Session-ID bereits in der Liste enthalten ist
    if (!sessionIds.includes(sessionId)) {
        sessionIds.push(sessionId); // Füge die neue Session-ID zur Liste hinzu
        localStorage.setItem('sessionIds', JSON.stringify(sessionIds)); // Speichere die Liste zurück in den Local Storage
    }

    return sessionId;
};


export const saveNodesToFirebase = (sessionId, nodeId, nodes, edges, isDragging) => {

    if (!isDragging) {
        // Prüfe, ob es sich um einen Node oder eine Edge handelt
        const nodeToSave = nodes.find(node => node.id === nodeId);
        const edgeToSave = edges.find(edge => edge.id === nodeId);

        if (nodeToSave) {
            // Veränderten Node speichern
            const sessionNodeRef = ref(database, `sessions/${sessionId}/nodes/${nodeId}`);

            // Entferne Funktionen aus dem Node
            const { onChange, ...dataWithoutFunctions } = nodeToSave.data || {};
            const nodeWithoutFunctions = {
                ...nodeToSave,
                data: dataWithoutFunctions,
            };

            // Speichere den Node in Firebase
            set(sessionNodeRef, nodeWithoutFunctions)
                .then(() => {
                    console.log(`Node ${nodeId} saved successfully.`);
                })
                .catch((error) => {
                    console.error(`Error saving node ${nodeId} to Firebase:`, error);
                });

            // Speichere den Node lokal
            saveNodeToLocalStorage(nodeId, nodeWithoutFunctions);

        } else if (edgeToSave) {
            // Veränderte Edge speichern
            const sessionEdgeRef = ref(database, `sessions/${sessionId}/edges/${nodeId}`);

            // Speichere die Edge in Firebase
            set(sessionEdgeRef, edgeToSave)
                .then(() => {
                    console.log(`Edge ${nodeId} saved successfully.`);
                })
                .catch((error) => {
                    console.error(`Error saving edge ${nodeId} to Firebase:`, error);
                });

            // Speichere die Edge lokal
            saveEdgeToLocalStorage(nodeId, edgeToSave);

        } else {
            console.error(`Node or Edge with ID ${nodeId} not found.`);
        }
    }
};

// Helferfunktionen zum Speichern in den LocalStorage
const saveNodeToLocalStorage = (nodeId, node) => {
    const nodes = JSON.parse(localStorage.getItem('nodes')) || {};
    nodes[nodeId] = node;
    localStorage.setItem('nodes', JSON.stringify(nodes));
};

const saveEdgeToLocalStorage = (edgeId, edge) => {
    const edges = JSON.parse(localStorage.getItem('edges')) || {};
    edges[edgeId] = edge;
    localStorage.setItem('edges', JSON.stringify(edges));
};


export const loadFromFirebase = (sessionId, setNodes, setEdges, isDragging) => {

    const currentTime = Date.now();
    if (!isDragging) {
        lastDownloadTime = currentTime;

        const sessionRef = ref(database, `sessions/${sessionId}`);
        onValue(sessionRef, (snapshot) => {
            if (!isDragging) {
                const data = snapshot.val();

                if (data && data.nodes) {
                    const { nodes: localNodesObject = {}, edges: localEdgesObject = {} } = loadFromLocalStorage();
                    const nodesAreEqual = JSON.stringify(localNodesObject) === JSON.stringify(data.nodes);
                    const edgesAreEqual = JSON.stringify(localEdgesObject) === JSON.stringify(data.edges);

                    if (isFirstLoad || !nodesAreEqual || !edgesAreEqual) {
                        setNodes(Object.values(data.nodes || {}));
                        setEdges(Object.values(data.edges || {}));
                        saveToLocalStorage(data.nodes || {}, data.edges || {});
                        isFirstLoad = false; // Nach dem ersten Ladevorgang Flag auf false setzen
                    }
                }
            }
        });
    } else {

    }
};

export const checkSessionExists = async (sessionId) => {
    const sessionRef = ref(database, `sessions/${sessionId}`);
    const snapshot = await get(sessionRef);
    return snapshot.exists();
};

export const saveToLocalStorage = (nodesObject, edgesObject) => {

    localStorage.setItem('nodes', JSON.stringify(nodesObject));
    localStorage.setItem('edges', JSON.stringify(edgesObject));
};

export const loadFromLocalStorage = () => {
    const nodes = JSON.parse(localStorage.getItem('nodes')) || {};
    const edges = JSON.parse(localStorage.getItem('edges')) || {};
    return { nodes, edges };
};

export const listenToNodesAndEdges = (sessionId, setNodes, setEdges, isDragging) => {
    const sessionRef = ref(database, `sessions/${sessionId}/nodes`);
    const edgesRef = ref(database, `sessions/${sessionId}/edges`);

    onValue(sessionRef, (snapshot) => {
        const currentTime = Date.now();
        if (!isDragging && currentTime - lastDownloadTime >= delay) {
            lastDownloadTime = currentTime;
            const data = snapshot.val();
            if (data) {
                setNodes(Object.values(data) || []);
            }
        }
    });

    onValue(edgesRef, (snapshot) => {
        const currentTime = Date.now();
        if (!isDragging && currentTime - lastDownloadTime >= delay) {
            lastDownloadTime = currentTime;
            const data = snapshot.val();
            if (data) {
                setEdges(Object.values(data) || []);
            }
        }
    });
};


export const saveNodePositionToFirebase = (sessionId, nodeId, node) => {
    const nodeRef = ref(database, `sessions/${sessionId}/nodes/${nodeId}`);
    set(nodeRef, node); // Speichere den gesamten Node in Firebase
};
