// src/nodes/lib/TextareaComponent.js
import React from 'react';

const TextareaComponent = ({ value, onChange, placeholder }) => (
  <textarea
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    className="master-node-textarea"
  />
);

export default TextareaComponent;
