// Beispiel LogComponent.js
import React from 'react';

const LogComponent = ({ logData }) => {
  return (
    <div>
      <h4>Log Output:</h4>
      <pre>{JSON.stringify(logData, null, 2)}</pre>
    </div>
  );
};

export default LogComponent;
