// src/nodes/lib/SliderComponent.js
import React from 'react';

const SliderComponent = ({ value, onChange, min, max, step }) => (
  <input
    type="range"
    min={min}
    max={max}
    step={step}
    value={value}
    onChange={onChange}
    className="master-node-slider"
  />
);

export default SliderComponent;
