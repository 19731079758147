import React from 'react';
import { Handle } from 'react-flow-renderer';

const GroupNode = ({ id, data, isSelected, onClick }) => {
  const nodeStyle = {
    width: 'auto',
    height: 'auto',
    padding: '10px',
    border: isSelected ? '2px solid blue' : '1px solid gray',
    backgroundColor: '#f0f0f0',
  };

  return (
    <div
      className={`group-node ${isSelected ? 'selected' : ''}`}
      style={nodeStyle}
      onClick={onClick}
    >
      <div className="group-node-header">
        <span>Group Node</span>
      </div>
      <div className="group-node-body">
        {/* Füge hier den Inhalt der gruppierten Nodes ein */}
      </div>
      <Handle type="source" position="right" />
      <Handle type="target" position="left" />
    </div>
  );
};

export default GroupNode;
