import React, { useState, useEffect, useRef } from 'react';
import './SearchComponent.css';

const SearchComponent = ({ nodeSetups, onAddNode, onClose }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const inputRef = useRef(null);

    // Focus on the input field when the component mounts
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    // Filter nodes based on the search term
    const filteredNodes = Object.keys(nodeSetups).filter(key => {
        const nodeTitle = nodeSetups[key].title.toLowerCase().trim(); // Trim title
        const searchValue = searchTerm.toLowerCase().trim(); // Trim search term
        const match = nodeTitle.includes(searchValue);
        console.log(`Checking node: "${nodeSetups[key].title}", Match: ${match}`);
        return match;
    });

    console.log('Filtered Nodes:', filteredNodes);

    const handleNodeClick = (key) => {
        console.log('Node clicked:', key); // Log the node that was clicked
        onAddNode(key);
        onClose(() => setSearchTerm('')); // Close the search component after a node is added and then reset searchTerm
    };

    return (
        <div className="search-component-overlay">
            <div className="search-component-container">
                <div className="search-component-header">
                    <span className="search-title">Search Neuron</span>
                    <input
                        ref={inputRef}
                        type="text"
                        className="search-component-input"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            console.log('Search Term:', e.target.value); // Log the current search term
                        }}
                    />
                </div>
                {searchTerm && filteredNodes.length > 0 && (
                    <div className="search-component-body">
                        <ul className="search-component-list">
                            {filteredNodes.map(key => {
                                const node = nodeSetups[key];
                                return (
                                    <li
                                        key={`${key}-${node.title}`} // Ensuring unique keys
                                        className="search-component-list-item"
                                        onClick={() => handleNodeClick(key)}
                                    >
                                        <div className="node-main-info">
                                            <span className="node-title">{node.title}</span>
                                        </div>
                                        <div className="node-io-info">
                                            <div className="node-inputs">
                                                {(node.imports || []).map((input, index) => (
                                                    <span
                                                        key={`${key}-import-${input.type || index}`} // Ensuring unique keys for imports
                                                        className="node-io-item"
                                                    >
                                                        <span
                                                            className="io-dot"
                                                            style={{ backgroundColor: input.color }}
                                                        ></span>
                                                        {input.title}
                                                    </span>
                                                ))}
                                            </div>
                                            <div className="node-outputs">
                                                {(node.exports || []).map((output, index) => (
                                                    <span
                                                        key={`${key}-export-${output.type || index}`} // Ensuring unique keys for exports
                                                        className="node-io-item"
                                                    >
                                                        <span
                                                            className="io-dot"
                                                            style={{ backgroundColor: output.color }}
                                                        ></span>
                                                        {output.title}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
                {searchTerm && filteredNodes.length === 0 && (
                    <div className="search-component-body">
                        <p>No results found for "{searchTerm}"</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SearchComponent;
