class NodeManager {
    constructor() {
        this.nodeData = {}; // Speichert den Zustand und die Daten aller Nodes
    }

    // Registriert eine Node im Manager
    registerNode(nodeId, expectedInputs) {
        if (!this.nodeData[nodeId]) {
            this.nodeData[nodeId] = {
                receivedInputs: 0,
                expectedInputs: expectedInputs,
                inputs: [], // Speichert die Reihenfolge der erhaltenen Eingaben
                isReady: false,
            };
        }
    }

    // Fügt Daten zu einer Node hinzu und prüft, ob sie bereit zur Ausführung ist
    addNodeData(nodeId, data) {
        
        if (this.nodeData[nodeId]) {
            this.nodeData[nodeId].receivedInputs += 1;

            // Daten der Node hinzufügen, indem sie zur inputs-Liste hinzugefügt werden
            this.nodeData[nodeId].inputs.push(data);

            // Überprüfung, ob die Node bereit zur Ausführung ist
            this.nodeData[nodeId].isReady = this.nodeData[nodeId].receivedInputs >= this.nodeData[nodeId].expectedInputs;
        }
    }

    // Überprüft, ob eine Node bereit zur Ausführung ist
    isNodeReady(nodeId) {
        return this.nodeData[nodeId]?.isReady || false;
    }

    // Gibt die gesammelten Daten für eine Node zurück
    getNodeData(nodeId) {
        return this.nodeData[nodeId]?.inputs || [];
    }

    // Löscht die Daten nach der Ausführung
    clearNode(nodeId) {
        delete this.nodeData[nodeId];
    }

    // Debugging-Methode, um den aktuellen Zustand des NodeManagers anzuzeigen
    debugState() {
        console.log(this.nodeData);
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new NodeManager();
