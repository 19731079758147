// nodeStatus.js

export const setNodeRunningStatus = (nodeId, isRunning) => {
    // Finde das Element, das das data-id Attribut mit der richtigen ID hat
    const nodeElement = document.querySelector(`[data-id="${nodeId}"] .master-node-header`);
  
    if (nodeElement) {
      if (isRunning) {
        console.log(`Setze Hintergrundfarbe auf grün für Node mit ID: ${nodeId}`);
        nodeElement.style.backgroundColor = '#27ae60'; // Setzt die Topbar auf grün
      } else {
        console.log(`Setze Hintergrundfarbe auf Standard für Node mit ID: ${nodeId}`);
        nodeElement.style.backgroundColor = ''; // Reset auf die Standardfarbe
      }
    } else {
      console.error(`Element nicht gefunden für Node mit ID: ${nodeId}`); // Debugging-Ausgabe
    }
  };
   